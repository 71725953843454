/* @font-face {
    font-family: 'PT Serif';
    src: url('./fonts/PTSerif-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PT Serif';
    src: url('./fonts/PTSerif-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'PT Serif';
    src: url('./fonts/PTSerif-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PT Serif';
    src: url('./fonts/PTSerif-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  
  body {
    font-family: 'PT Serif', serif;
  } */

  /* PT Serif */
@font-face {
  font-family: 'PT Serif';
  src: url('./fonts/PTSerif-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'PT Serif';
  src: url('./fonts/PTSerif-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'PT Serif';
  src: url('./fonts/PTSerif-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'PT Serif';
  src: url('./fonts/PTSerif-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/* Add Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Funnel+Display:wght@300..800&family=Playwrite+IE+Guides&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sour+Gummy:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DynaPuff:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap');


